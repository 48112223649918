import {EVENT_FILTER_TYPE, ExperimentNames, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import settingsParams from '../../settingsParams'
import {SettingsUpdatedActionType} from '../../Widget/actions/sdk'
import {getLoadedEventCount, hasMore, isLoading} from '../selectors/events'
import {createAsyncAction} from '../services/redux-toolkit'
import {updateComponentDraft} from './component-config'
import {editorSettingsMyEventsShowSetup, selectSingleWidgetEvent} from './config'

interface SetActiveEventParams {
  event: wix.events.Event
  singleEventWidget: boolean
  recurringFilter: number
}

export const setActiveEvent = createAsyncAction<void, SetActiveEventParams>(
  'SET_ACTIVE_EVENT',
  async ({event, singleEventWidget, recurringFilter}, {getState, extra: {bi, Wix}}) =>
    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget,
      recurringFilter,
      eventId: singleEventWidget ? event.id : null,
      Wix,
    }),
)

interface UpdateComponentDraftAndSetActiveEventParams {
  event: wix.events.Event
  singleEventWidget: boolean
  recurringFilter: number
}

export const updateComponentDraftAndSetActiveEvent = createAsyncAction<
  void,
  UpdateComponentDraftAndSetActiveEventParams
>(
  'UPDATE_COMPONENT_DRAFT_AND_SET_ACTIVE_EVENT',
  async ({event, singleEventWidget, recurringFilter}, {dispatch, extra: {experiments, settings}}) => {
    const newSelectManuallyEnabled = experiments.enabled(ExperimentNames.SelectEventsManually)

    if (newSelectManuallyEnabled || settings.get(settingsParams.filterType) !== undefined) {
      await dispatch(selectSingleWidgetEvent(event.id))
    } else {
      await dispatch(updateSelectedEvents([event.id]))
    }

    dispatch(setActiveEvent({event, singleEventWidget, recurringFilter}))
  },
)

interface LoadActiveEventParams {
  filterType: EVENT_FILTER_TYPE
}

export const loadActiveEvent = createAsyncAction<wix.events.Event, LoadActiveEventParams>(
  'LOAD_ACTIVE_EVENT',
  async ({filterType}, {getState, extra: {serverApi, experiments, settings}}) => {
    const state = getState()

    if (
      (experiments.enabled(ExperimentNames.SelectEventsManually) ||
        settings.get(settingsParams.filterType) !== undefined) &&
      filterType === EVENT_FILTER_TYPE.MANUAL
    ) {
      const {events} = await serverApi.listCategoryEvents({
        offset: 0,
        categoryId: state.myEvents.componentCategory.id,
      })
      return events[0]
    }

    const eventId = state.componentConfig.byEventId?.eventId?.[0]

    if (eventId) {
      const {event} = await serverApi.loadActiveEvent(eventId)
      return event
    }
  },
)

interface LoadEventsParams {
  filterType: EVENT_FILTER_TYPE
  widgetType: WIDGET_TYPE
  fromBeginning?: boolean
}

interface LoadEventsResponse {
  events: wix.events.Event[]
  facets: {status?: wix.events.FacetCounts; recurrenceStatus?: wix.events.FacetCounts}
}

export const loadEvents = createAsyncAction<LoadEventsResponse, LoadEventsParams>(
  'LOAD_EVENTS',
  async ({filterType, widgetType, fromBeginning}, {getState, extra: {serverApi, experiments, settings}}) => {
    const state = getState()
    const offset = fromBeginning ? 0 : getLoadedEventCount(state)

    if (
      (experiments.enabled(ExperimentNames.SelectEventsManually) ||
        settings.get(settingsParams.filterType) !== undefined) &&
      filterType === EVENT_FILTER_TYPE.MANUAL &&
      widgetType === WIDGET_TYPE.LIST
    ) {
      const {events} = await serverApi.listCategoryEvents({offset, categoryId: state.myEvents.componentCategory.id})
      return {events, facets: {}}
    }

    return serverApi.loadEvents({offset, categoryId: state.componentConfig?.byCategoryId?.categoryId})
  },
  {
    condition: ({fromBeginning}, {getState}) => {
      const state = getState()
      return fromBeginning || (!isLoading(state) && hasMore(state))
    },
  },
)

export const showEvent = createAsyncAction<void, string>('SHOW_EVENT', async (eventId, {dispatch, getState}) => {
  const state = getState()
  const {componentConfig} = state
  const eventIds = [...componentConfig.byEventId.eventId]

  eventIds.push(eventId)

  await dispatch(updateSelectedEvents(eventIds))
})

export const hideEvent = createAsyncAction<void, string>(
  'HIDE_EVENT',
  async (eventId, {dispatch, getState, extra: {Wix}}) => {
    const state = getState()
    const {componentConfig} = state
    let eventIds = [...componentConfig.byEventId.eventId]

    Wix.Settings.triggerSettingsUpdatedEvent({type: SettingsUpdatedActionType.HIDE_EVENT, payload: eventId})

    eventIds = eventIds.filter(evId => evId !== eventId)

    await dispatch(updateSelectedEvents(eventIds))
  },
)

export const updateSelectedEvents = createAsyncAction<void, string[]>(
  'UPDATE_SELECTED_EVENTS',
  async (eventIds, {dispatch, getState}) => {
    const state = getState()
    const {componentConfig} = state

    dispatch(
      updateComponentDraft({
        byStatus: undefined,
        byEventId: {...componentConfig.byEventId, eventId: eventIds},
      }),
    )
  },
)
