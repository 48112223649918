import {EventStatus, StatusFilter, Status} from '@wix/events-types'
import {
  RECURRING_FILTER,
  getRecurrenceStatus,
  isRecurringNextEvent,
  getRecurrenceCategoryId,
  EVENT_FILTER_TYPE,
  isRecurringLastEndedEvent,
} from '@wix/wix-events-commons-statics'
import {WidgetSettingsState} from '../reducers'
import {getEventFilterType} from './component-config'
import {activeRecurringFilter} from './settings'

export const getActiveEvent = (state: WidgetSettingsState) => state.events.active || {scheduling: {config: {}}}

export const countEvents = (state: WidgetSettingsState) => state.events.all.length

export const eventsExist = (state: WidgetSettingsState) => Boolean(countEvents(state))

interface GetEventsParams {
  state: WidgetSettingsState
  statusFilter?: EventStatus[]
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const getEvents = ({state, statusFilter, multiEventWidget, calendar, recurringFilter}: GetEventsParams) => {
  let allEvents = state.events.all

  if (activeRecurringFilter({state, multiEventWidget, calendar, recurringFilter}) === RECURRING_FILTER.COLLAPSED) {
    const recurringNextCategories = allEvents.filter(isRecurringNextEvent).map(getRecurrenceCategoryId)
    const upcomingAndPast = getEventFilterType(state) === EVENT_FILTER_TYPE.UPCOMING_AND_PAST

    allEvents = allEvents.filter(event => {
      if (getRecurrenceStatus(event) === Status.RECURRING) {
        return false
      }

      if (
        upcomingAndPast &&
        isRecurringLastEndedEvent(event) &&
        recurringNextCategories.includes(getRecurrenceCategoryId(event))
      ) {
        return false
      }

      return true
    })
  }

  if (statusFilter) {
    return allEvents.filter(event => statusFilter.includes(event.status))
  }

  return allEvents
}

export const getCategoryEvents = ({state, multiEventWidget, calendar, recurringFilter}: GetEventsParams) =>
  getEvents({
    state,
    statusFilter: [EventStatus.SCHEDULED, EventStatus.STARTED, EventStatus.ENDED, EventStatus.CANCELED],
    multiEventWidget,
    calendar,
    recurringFilter,
  })

export const getUpcomingEvents = ({state, multiEventWidget, calendar, recurringFilter}: GetEventsParams) =>
  getEvents({
    state,
    statusFilter: [EventStatus.SCHEDULED, EventStatus.STARTED],
    multiEventWidget,
    calendar,
    recurringFilter,
  })

export const getPastEvents = ({state, multiEventWidget, calendar, recurringFilter}: GetEventsParams) =>
  getEvents({state, statusFilter: [EventStatus.ENDED], multiEventWidget, calendar, recurringFilter})

export const getCancelledEvents = ({state, multiEventWidget, calendar, recurringFilter}: GetEventsParams) =>
  getEvents({state, statusFilter: [EventStatus.CANCELED], multiEventWidget, calendar, recurringFilter})

export const getUpcomingAndPastEvents = ({state, multiEventWidget, calendar, recurringFilter}: GetEventsParams) =>
  getEvents({
    state,
    statusFilter: [EventStatus.SCHEDULED, EventStatus.STARTED, EventStatus.ENDED],
    multiEventWidget,
    calendar,
    recurringFilter,
  })

interface GetEventIdsByStatusFilterParams {
  state: WidgetSettingsState
  filter: StatusFilter
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const getEventIdsByStatusFilter = ({
  state,
  filter,
  multiEventWidget,
  calendar,
  recurringFilter,
}: GetEventIdsByStatusFilterParams) => {
  const eventIdMap = {
    [StatusFilter.FUTURE_ONLY]: () =>
      getUpcomingEvents({state, multiEventWidget, calendar, recurringFilter}).map(event => event.id),
    [StatusFilter.FUTURE_AND_PAST]: () =>
      getUpcomingAndPastEvents({state, multiEventWidget, calendar, recurringFilter}).map(event => event.id),
    [StatusFilter.PAST_ONLY]: () =>
      getPastEvents({state, multiEventWidget, calendar, recurringFilter}).map(event => event.id),
  }

  return eventIdMap[filter]()
}

export const isDemoEvents = (state: WidgetSettingsState) => !state.siteSettings.firstEventCreated

export const isLoading = (state: WidgetSettingsState) => state.events.loading
export const hasMore = (state: WidgetSettingsState) => state.events.hasMore
export const getLoadedEventCount = (state: WidgetSettingsState) => state.events.all.length

export const hasRecurringEvents = (state: WidgetSettingsState) => {
  const counts = state.events.facets?.recurrenceStatus?.counts || {}
  return counts.RECURRING_NEXT || counts.RECURRING
}
